<template>
    <!--#region TABLE RAW MATERIALS (TEMPORAL - ALTA DE UNA OPERACIÓN Y DETALLE DE OPERACION, ESPECIALISTA Y OPERACIONES DE CAJA) -->
    <v-container class="pt-0" fluid>
        <div class="content-title-add-material-detail-oc">
            <p class="text-title-table">Materias primas y pesaje</p>
            <v-spacer></v-spacer>
        </div>
        <v-data-table v-show="!isMobile" mobile-breakpoint="0" :loading="bLoadingTable" :items-per-page="iItemsPerPage"
            :headers="aHeadersRawMaterials" :disable-sort="true" :items="aRawMaterial" :hide-default-footer="true"
            no-data-text="No hay información disponible para mostrar." loading-text="Cargando datos..." :footer-props="{
                'items-per-page-text': 'Filas por página',
                'items-per-page-all-text': 'Todos',
            }" class="global-table">
            <template slot="progress">
                <v-progress-linear color="var(--primary-color-loading-table)" indeterminate></v-progress-linear>
            </template>
            <template v-slot:[`item.sCodeRawMaterial`]="{ item }">
                <div class="content-color-id-raw-material">
                    <div :style="{
                        'background-color': '#' + item.sColorKeyRawMaterial + '!important',
                    }" class="color-raw-material"></div>
                    <span>{{ item.sCodeRawMaterial }}</span>
                </div>
            </template>
            <template v-slot:[`item.dWeightRawMaterial`]="{ item }">
                <span>{{ formatMoneyGlobal(item.dWeightRawMaterial) }}
                    {{ item.sMeasurementUnitRawMaterial }} {{ item.bModified ? '(modificado)' : '' }} </span>
            </template>
            <template v-slot:[`item.dUnitPriceRawMaterial`]="{ item }">
                <span>${{ formatMoneyGlobal(item.dUnitPriceRawMaterialMXN) }} MXN / ${{ formatMoneyGlobal(item.dUnitPriceRawMaterialUSD) }} USD</span>
            </template>
            <template v-slot:[`item.dSubtotalRawMaterial`]="{ item }">
                <span>${{ formatMoneyGlobal(item.dSubtotalRawMaterialMXN) }} MXN / ${{ formatMoneyGlobal(item.dSubtotalRawMaterialUSD) }} USD </span>
            </template>
            <template v-slot:[`item.dUnprocessedWeight`]="{ item }">
                <span>{{ formatMoneyGlobal(item.dUnprocessedWeight) }} Kg</span>
            </template>
            <template v-slot:[`item.accions`]="{ item }">
                <v-btn v-if="!bAddOP" @click="setDialogImgsOP(item)" icon>
                    <span class="material-icons icon-white">
                        image_search
                    </span>
                </v-btn>
                <span v-if="bShowEditMerma">
                    <span v-if="bAdminPermissions">
                        <v-btn v-if="!bIdOrderStatusFinaly && !bAddOP" icon
                            @click="bModifyWaste = true, targetItem = item">
                            <v-icon>
                                mdi-bucket-outline
                            </v-icon>
                        </v-btn>
                    </span>
                </span>
                <span v-if="bShowEditMaterial">
                    <span v-if="bAdminPermissions">
                        <v-btn v-if="!bIdOrderStatusFinaly && !bAddOP" icon
                            @click="bModifyMaterial = true, targetItem = item">
                            <v-icon>
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </span>
                </span>


                <v-btn v-if="bDeleteRawMaterial" v-show="bAdminPermissions" @click="setDeleteItem(item)"
                    class="global-btn-table-delete" icon>
                    <v-icon> mdi-trash-can-outline </v-icon>
                </v-btn>
            </template>
            <template v-slot:footer>
                <footer-table-component-global @changeTable="getItem" :oItem="oItem"
                    @setDialogConfirmationGlobal="setDialogConfirmationGlobal" :bAdminPermissions="bAdminPermissions"
                    :bCloseBtnRawMaterial="bCloseBtnRawMaterial" :bBlockAddRawMaterial="bBlockAddRawMaterial"
                    :numPages="numPages" :totalItems="totalItems" />
            </template>
        </v-data-table>
        <table-mobile-raw-materials-global v-show="isMobile" @changeTable="getItem"
            @setItemDialogConfirmationGlobal="setDialogConfirmationGlobal" @openDeleteItem="setDeleteItem"
            @setDialogImgsOP="setDialogImgsOP" :aTable="aRawMaterial" :headers="aHeadersRawMaterials" :oItem="oItem"
            :bAdminPermissions="bAdminPermissions" :bBlockAddRawMaterial="bBlockAddRawMaterial" :numPages="numPages"
            :totalItems="totalItems" />

        <dialog-action-confirm-global @setDialogActionConfirmGlobal="setDialogConfirmationGlobal"
            :bDialogActionConfirm="bDialogConfirmationGlobal" :oDialogConfirm="oDialogConfirmGlobal" />

        <v-modify-waste @close="closeWeightDialog" @refresh="getItem" :oPurchaseOrder="targetItem"
            :active="bModifyWaste"></v-modify-waste>
        <v-modify-material @close="bModifyMaterial = false" @refresh="getItem" :oPurchaseOrder="targetItem"
            :active="bModifyMaterial"></v-modify-material>

    </v-container>
    <!--#endregion TABLE RAW MATERIALS -->

</template>

<script>
import ModifyWasteOP from '../../operations_detail_component_global/ModifyWasteOP.vue';
import ModifyMaterialOP from '../../operations_detail_component_global/ModifyMaterialOP.vue';

export default {
    props: {
        aHeadersRawMaterials: Array,
        aRawMaterial: Array,
        bAdminPermissions: Boolean,
        oItem: Object,
        bBlockAddRawMaterial: Boolean,
        numPages: Number,
        totalItems: Number,
        iItemsPerPage: Number,
        bLoadingTable: {
            default: Boolean,
            type: true
        }
    },
    data() {
        return {
            isMobile: false,
            oDialogConfirmGlobal: {},
            bCloseBtnRawMaterial: false,
            bDialogConfirmationGlobal: false,
            bModifyWaste: false,
            bModifyMaterial: false,
            targetItem: null,
            aDeleteRawMaterial: ["AddOperation"],
            sIdOrderStatus: "",
            bIdOrderStatusFinaly: false,
            aShowEditMerma:["OPGeneralDetail","OPDetailReport","OPPendingDetail","OPTransationDetailAccount","OPDetailTransactionDetail"],
            aShowEditMaterial:["OPGeneralDetail","OPDetailReport","OPTransationDetailAccount","OPDetailTransactionDetail"]
        }
    },
    created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        closeWeightDialog() {
            this.bModifyWaste = false
            this.targetItem = null
        },
        handleResize() {
            if (window.innerWidth > 599) {
                this.isMobile = false;
            } else {
                this.isMobile = true;
            }
        },
        setDialogConfirmationGlobal(oItem) {
            if (this.bAddOP) {
                this.$emit("getItem", oItem.oRawMaterial);
            } else {
                this.oDialogConfirmGlobal = oItem;
                if (this.oDialogConfirmGlobal === undefined) {

                    this.bCloseBtnRawMaterial = !this.bCloseBtnRawMaterial;
                }
                this.bDialogConfirmationGlobal = !this.bDialogConfirmationGlobal;
            }
        },
        getItem(aRawMaterial) {
            this.$emit("getItem", aRawMaterial);
        },
        setRawMaterials(aRawMaterial) {
            // this.$emit("getRawMaterials",aRawMaterial)
        },
        setDeleteItem(oItem) {
            this.$emit("setDeleteItem");
        },
        setDialogImgsOP(oItem) {
            this.$emit("setDialogImgsOP", oItem, false)
        },
        setOrderIdStatus() {
            this.sIdOrderStatus = this.oItem.oOrderStatus.sStatusId
            this.bIdOrderStatusFinaly = (this.sIdOrderStatus !== "a5d69e14-9576-49be-bb7f-e33dd25ae011");
            this.bIdOrderStatusFinaly = (this.oItem.oOrderStatus.sName === "FINALIZADA");
        },
    },
    computed: {
        bWasteActive() {
            return this.$route.name == 'OPGeneralDetail'
        },
        bShowEditMerma(){
            return this.aShowEditMerma.filter((e)=> e === this.$route.name).length > 0
        },
        bShowEditMaterial(){
            return this.aShowEditMaterial.filter((e)=> e === this.$route.name).length > 0
        },
        bAddOP() {
            return this.$route.name === "AddOperation"
        },
        bDeleteRawMaterial() {
            return (
                this.aDeleteRawMaterial.filter((e) => e === this.$route.name)
                    .length > 0
            );
        }
    },
    watch: {
        oItem() {
            this.setOrderIdStatus();
        }
    },
    components: {
        VModifyWaste: ModifyWasteOP,
        VModifyMaterial: ModifyMaterialOP
    }
}
</script>

<style scoped>
.content-title-add-material-detail-oc {
    display: flex;
    margin-top: 0px;
    margin-bottom: 0px;
}

.text-title {
    margin-bottom: 2px;
    color: var(--primary-color-text-title-detail);
    font-family: "pop-Regular";
    opacity: 1;
    letter-spacing: 0px;
}

.text-information {
    margin-bottom: 5px;
    color: var(--primary-color-text);
    font-family: "pop-Regular";
}

.text-title-table {
    text-align: start;
    letter-spacing: 4.8px;
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 14px;
    /* margin-right: 10px; */
    font-family: "pop-Bold";
    color: var(--primary-color-color-title-information);
}

.content-btn-add-material-detail-oc {
    width: 200px;
}

.content-color-id-raw-material {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
}

.color-raw-material {
    height: 12px;
    width: 12px;
    border-radius: 100%;
    margin-top: -1px;
    margin-right: 10px;
    border: 1px solid var(--primary-color-text);
}

.text-icon-launch {
    color: var(--primary-color-link);
    /* margin-top: -5px; */
    font-size: 20px;
    cursor: pointer;
}

.content-title-information {
    letter-spacing: 4.8px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 14px;
    font-family: "pop-Bold";
    color: var(--primary-color-color-title-information);
}

.content-infomation-data-expansion {
    height: auto;
}

.v-expansion-panel {
    background-color: transparent !important;
}

.icon-arrow-information {}

.icon-arrow-informationOpen {
    transform: rotate(-4.700rad);
}

.icon-arrow-transport {}

.icon-arrow-transportOpen {
    transform: rotate(-4.700rad);

}

.icon-arrow-current-order-statusOpen {
    transform: rotate(-4.700rad);

}

.content-accumulated-transaction-amount {
    background-color: var(--primary-color-background-table);
    color: var(--primary-color-text);
    height: 70px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-end;
    text-align: end;
    align-items: center;
    padding: 10PX;
}

.title-amount {
    font-family: 'pop-Regular';
    font-size: 28px;
    color: var(--primary-color-text-yellow);
}

.text-amount {
    font-family: 'pop-Regular';
    font-size: 30px;
    color: var(--primary-color-text);
    margin-left: 10px;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .content-accumulated-transaction-amount {
        background-color: var(--primary-color-background-table);
        color: var(--primary-color-text);
        height: auto;
        border-radius: 10px;
        display: block;
        justify-content: flex-end;
        text-align: end;
        align-items: center;
        padding: 10PX;
    }

    .title-amount {
        font-family: 'pop-Regular';
        font-size: 28px;
        color: var(--primary-color-text-yellow);
    }

    .text-amount {
        font-family: 'pop-Regular';
        font-size: 30px;
        color: var(--primary-color-text);
        margin-left: 0px;
    }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>